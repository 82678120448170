import React, { useEffect, useRef } from "react";
import "./index.css";
import bg from "@/static/img/careers/careersbg2.png";
import { useInView } from "react-intersection-observer";
import { classnames } from "@/utils";
import { FormattedMessage, useIntl } from "react-intl";

const SectionSeven = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  useEffect(() => {
    if (inView) {
      ref1.current.className = classnames(
        ref1.current.className,
        "animation-careers-title"
      );
      ref2.current.className = classnames(
        ref2.current.className,
        "animation-careers-title"
      );
    }
  }, [inView]);

  const intl = useIntl();

  return (
    <>
      <div className="section-seven-container" ref={ref}>
        <img alt="bg" src={bg} className={"section-seven-container-bg"} />

        <div className="section-seven-title" ref={ref1}>
          <FormattedMessage id="every_morning_you" />
        </div>

        <div className="signature" ref={ref2}>
          - Vincent, <FormattedMessage id="founder_of_construct_tech" />
        </div>

        <div className="founderlineicon"></div>

        {intl.locale !== "en" && (
          <div className="company-info">
            <div>
              <strong>公司名称: </strong>
              CONSTRUCT TECHNOLOGY PTE.LTD
            </div>

            <div>
              <strong>地址:</strong>
              15 SCOTTS ROAD #03-12 15 SCOTTS SINGAPORE (228218)
            </div>

            <div>
              <storng>邮箱: </storng>
              lit@litatom.com
            </div>

            <strong
              className="company-info--privacy"
              onClick={() =>
                (window.location.href =
                  "https://www.litmatchapp.com/#/privacy-policy?loc=TW")
              }
            >
              隐私协议
            </strong>
          </div>
        )}
      </div>
    </>
  );
};

export default SectionSeven;
