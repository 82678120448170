import React from "react"
import culture1 from '@/static/img/mobilecareers/culture1.png'
import culture2 from '@/static/img/mobilecareers/culture2.png'
import culture3 from '@/static/img/mobilecareers/culture3.png'
import culture4 from '@/static/img/mobilecareers/culture4.png'
import { FormattedMessage } from "react-intl";
import "./index.css"

const cultures = [
  {
    key: 'culture1',
    img: culture1,
    title: "user_value_first",
    description: "users_needs_are",
    position: 'right',
  },
  {
    key: 'culture2',
    img: culture2,
    title: 'aim_for_the',
    description: "every_member_of",
    position: 'left',
  },
  {
    key: 'culture3',
    img: culture3,
    title: 'quick_action',
    description: "quick_action_enables",
    position: 'right',
  },
  {
    key: 'culture4',
    img: culture4,
    title: 'straightforward_transparent',
    description: "trust_is_the",
    position: 'left',
  }
]


const SectionFour = () => {
  return (
    cultures.map((c) => {
      return (
        <div className="mobile-careers-section-five-container" key={c.key}>
          <img src={c.img} className="mobile-career-benefit" />

          <div className="mobile-careers-section-text-container">
            <div className="mobile-careers-section-five-title">
            <FormattedMessage id={c.title}/> 
            </div>
            <div className="mobile-career-section-five-subtitle">
            <FormattedMessage id={c.description}/> 
            </div>
          </div>
        </div>
      )
    })

  );
}

export default SectionFour;