import React, { useEffect, useRef } from "react";
import "./index.css";
import capa1 from "@/static/img/capa1.png";
import capa2 from "@/static/img/capa2.png";
import capa3 from "@/static/img/capa3.png";
import { classnames } from "@/utils/index";
import { FormattedMessage } from "react-intl";

import { useInView } from "react-intersection-observer";

const capabilities = [
  {
    img: capa1,
    text: "multimedia_interaction",
    subText: "make_you_feel",
    key: "multimedia_interaction",
  },
  {
    img: capa2,
    text: "algorithm",
    subText: "understand_what_you",
    key: "Algorithm",
  },
  {
    img: capa3,
    text: "security_technology",
    subText: "set_everything_encrypted",
    key: "Security technology",
  },
];

const SectionSix = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const refs = [ref2, ref3, ref4];
  useEffect(() => {
    if (inView) {
      ref1.current.className = classnames(
        ref1.current.className,
        "animation-history-title-showUp"
      );
      refs.forEach((ref) => {
        ref.current.className = classnames(
          ref.current.className,
          "animation-capabilityItem-showUp"
        );
      });
    }
  }, [inView]);

  return (
    <div className="section-six-container" ref={ref}>
      <div className={`section-six-title`} ref={ref1}>
        Capability
      </div>
      <div className="capability-container">
        {capabilities.map((h, index) => {
          return (
            <div
              className={`capabilityItem`}
              key={h.key}
              style={{
                animationDelay: `${index * 0.1}s`,
              }}
              ref={refs[index]}
            >
              <div className="capabilityItemPart1">
                <img src={h.img} className="capabilitylogo" alt="" />
              </div>

              <div className="capabilityItemPart2">
                <FormattedMessage id={h.text} />
              </div>

              <div className="capabilityItemPart3">
                <FormattedMessage id={h.subText} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionSix;
