import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { FormattedMessage } from "react-intl";

import "./index.css";
import unilogo from "@/static/img/wwb/wwbunilogo.png";
import UNIintroVideo from "@/static/video/mobile-UNIintro.mov";
import { classnames } from "@/utils/index";

const SectionTwo = ({ navigatorVisibility }) => {
  const { ref: videoInViewRef, inView } = useInView({
    threshold: 0,
  });
  const videoRef = useRef(null);

  const { ref: titleInViewRef, inView: inView1 } = useInView({
    threshold: 0,
  });

  const titleRef = useRef(null);
  useEffect(() => {
    // if(!inView){
    //   videoRef.current.pause()
    // } else {
    //   videoRef.current.play()
    // }
  }, [inView]);

  useEffect(() => {
    if (inView1) {
      titleRef.current.className = classnames(
        titleRef.current.className,
        "animation-enter-up"
      );
    }
  }, [inView1]);

  return (
    <div
      className="mobile-what-we-build-section-two-container"
      id="mobile-what-we-build-section-two-container"
    >
      <div className="mobile-what-we-build-section-two">
        <div
          className="mobile-what-we-build-section-two-title animation-enter-initial-properties"
          ref={titleRef}
        >
          <img
            src={unilogo}
            className="mobile-wwb-unilogo"
            ref={titleInViewRef}
          />
          <div className="mobile-wwb-universe">Universe</div>
          <div className="mobile-wwb-cttf">
            <FormattedMessage id="cttfi" />
          </div>
          <div className="mobile-coming-soon-text">Coming soon</div>
        </div>
        <div
          className="mobile-wwb-section2-video-container"
          ref={videoInViewRef}
        >
          <video
            className="mobile-wwb-section2-video"
            style={{
              visibility: !navigatorVisibility ? "visible" : "hidden",
            }}
            playsInline
            autoPlay
            muted
            loop
            ref={videoRef}
          >
            <source src={UNIintroVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
