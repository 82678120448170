import React, {useRef, useEffect} from "react"
import "./index.css"
import foundationlogo from '@/static/img/foundationlogo.png'
import litmatchlogo from '@/static/img/litmatchlogo.png'
import UNIlogo from '@/static/img/UNIlogo.png'
import { useInView } from 'react-intersection-observer'
import { classnames } from '@/utils'
import { FormattedMessage } from 'react-intl';

const historys = [
    {
        img: foundationlogo,
        key: "construct_founded",
        year: 2019,
    },
    {
        img: litmatchlogo,
        key: "first_million_users",
        year: 2021,
    },
    {
        img: UNIlogo,
        
        key: "launch_of_creative",
        year: 2022,
    }
]

const SectionFive = () => {

    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const refs = [ ref2, ref3, ref4]

    const { ref, inView } = useInView({
        threshold: 0.3,
    })

      useEffect(() => {
        if(inView){
            ref1.current.className = classnames(ref1.current.className, 'animation-history-title-showUp')
            refs.forEach(ref => {
                ref.current.className = classnames(ref.current.className, 'animation-history-showUp')
            })
        }
      }, [inView])
    


    return (
        <div className="section-five-container" ref={ref}>
            <div className={`section-five-title`} ref={ref1}>
                <FormattedMessage id="milestones"/> 
            </div>
            <div className="history-container">
                {
                    historys.map((h, index) => {
                        return (
                            <div className={`historyItem`} key={h.key} style={{
                                animationDelay: `${index * 0.1}s`
                            }} ref={refs[index]}>
                                <div className="historyItemPart1"><img src={h.img} className="historylogo" /></div>
                                <div className="historyItemPart2"><FormattedMessage id={h.key}/> </div>
                                <div className="historyItemPart3">{h.year}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default SectionFive;
