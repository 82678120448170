import React, { useEffect, useRef } from "react"
import "./index.css"
import { openDialog } from '@/components/promisifiedComponent'

import arrowDown from "@/static/img/arrowDown.png";
import homeVideo from '@/static/video/home.mp4';
import official from '@/static/video/official.mov';
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl';

const SectionOne = () => {

  const { ref, inView } = useInView({
    threshold: 0,
  })
  const videoRef = useRef(null);
  useEffect(() => {
    if(!inView){
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }, [inView])

  const onClickArrow = () => {
    document.getElementById('home-section-two').scrollIntoView({ behavior: 'smooth'})
  }

  const handleModalVisible = () => {
    openDialog({
      content: 
      <video  playsInline className="official" autoPlay muted loop controls>
        <source src={official} type="video/mp4" />
      </video>,
      showCancelButton: false,
      showConfirmButton: false
    })
  }

  return (
    <div className="section-one-container">
      <div className="arrow-pointer bounce" onClick={onClickArrow}><img src={arrowDown} className="arrow-img"/></div>
      <div className="left-slogan">
        <div className="left-slogan-text">
          <FormattedMessage id={"construct_a_virtual"}/>
        </div>
        <div className="play-icon" onClick={() => handleModalVisible()}>
          <div className="watchIcon" />
          <span className="watchText" >Watch</span>
        </div>
      </div>
      <div className="main-page-section1-video-container" ref={ref}>
        <video  playsInline className="main-page-section1-video" muted loop ref={videoRef}>
          <source src={homeVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default SectionOne;
