import React, { useEffect, useRef } from "react"
import { useInView } from 'react-intersection-observer'

import section4litmatch1 from '@/static/gif/section4litmatch1.gif';
import section4litmatch2 from '@/static/gif/section4litmatch2.gif';
import textbg1 from '@/static/img/home/textbg1.png';
import textbg2 from '@/static/img/home/textbg2.png';
import { classnames } from '@/utils'
import "./index.css"
import { FormattedMessage } from 'react-intl';
const SectionFour = () => {
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0.1,
  })

  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView1) {
      ref3.current.className = classnames(ref3.current.className, 'animation-leftIn')
      ref4.current.className = classnames(ref4.current.className, 'animation-rightIn')
    }
  }, [inView1])

  useEffect(() => {
    if (inView2) {
      ref5.current.className = classnames(ref5.current.className, 'animation-rightIn')
      ref6.current.className = classnames(ref6.current.className, 'animation-leftIn')
    }
  }, [inView2])

  return (
    <div className="section-four-container">
      <div className="section-four-row-one" ref={ref1}>
        <img src={textbg1} className="section4-textbg1" />
        <img src={section4litmatch1} className={`section4litmatch1`} ref={ref3} />
        <div className={`section-four-row-one-text-container`} ref={ref4}>
          <div className={'section-four-row-one-text'}>
            <div className="section-four-text-fragment-one">
              <FormattedMessage id="deliver_love_trust"/>
              </div>
            <div className="section-four-text-fragment-two">
              <FormattedMessage id="through_the_online"/>
             </div>
          </div>
        </div>
      </div>
      <div className="section-four-row-two" ref={ref2}>
        <img src={textbg2} className="section4-textbg2" />
        <img src={section4litmatch2} className={`section4litmatch2`} ref={ref5} />
        <div className={`section-four-row-two-text-container`} ref={ref6}>
          <div className="section-four-text-fragment-three">
            <FormattedMessage id="create_tools_to"/>
          </div>
          <div className="section-four-text-fragment-four">
            <FormattedMessage id="human_exploration_and"/>
          </div>
        </div>
      </div>
      <div className="section-four-footer" />
    </div>
  );
}

export default SectionFour;
