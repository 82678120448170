import React, { useEffect, useState, useRef } from "react"
import { Carousel } from 'antd'
import { debounce } from 'lodash';

import "./index.css"
import culture1 from '@/static/img/careers/culture1.png'
import culture2 from '@/static/img/careers/culture2.png'
import culture3 from '@/static/img/careers/culture3.png'
import culture4 from '@/static/img/careers/culture4.png'
import leftIcon from '@/static/img/careers/leftIcon.png'
import rightIcon from '@/static/img/careers/rightIcon.png'
import { FormattedMessage } from "react-intl";

const cultures = [
  {
    key: 'culture1',
    img: culture1,
    title: "user_value_first",
    description: "users_needs_are",
    position: 'right',
  },
  {
    key: 'culture2',
    img: culture2,
    title: 'aim_for_the',
    description: "every_member_of",
    position: 'left',
  },
  {
    key: 'culture3',
    img: culture3,
    title: 'quick_action',
    description: "quick_action_enables",
    position: 'right',
  },
  {
    key: 'culture4',
    img: culture4,
    title: 'straightforward_transparent',
    description: "trust_is_the",
    position: 'left',
  }
]
const SectionOne = () => {
  const [selected, setSelected] = useState(0);
  const activeIndex = useRef(0);
  const carousel = useRef(null);
  const animationIsOngoing = useRef(false)


  useEffect(() => {
    const timer = setInterval(() => {
      if (!animationIsOngoing.current) {
        animationIsOngoing.current = true;
        carousel.current.next();
        activeIndex.current = (activeIndex.current + 1) % cultures.length
        setSelected(activeIndex.current)
      }
    }, 5000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const onClickNext = debounce(() => {
    if (!animationIsOngoing.current) {
      animationIsOngoing.current = true;
      carousel.current.next();
      activeIndex.current = (activeIndex.current + 1) % cultures.length
      setSelected(activeIndex.current)
    }
  }, 200)
  const onClickPrev = debounce(() => {
    if (!animationIsOngoing.current) {
      animationIsOngoing.current = true;
      carousel.current.prev();
      activeIndex.current = (activeIndex.current + cultures.length - 1) % cultures.length
      setSelected(activeIndex.current)
    }
  }, 200)

  const goTo = debounce((index) => {
    if (!animationIsOngoing.current) {
      animationIsOngoing.current = true;
      carousel.current.goTo(index, false)
      activeIndex.current = index
      setSelected(activeIndex.current)
    }
  }, 200)

  return (
    <div className="careers-section-four-container">
      <div className="careers-section-four-snapshots">
        {cultures.map((s, index) => {
          return (
            <div
              key={s.key}
              className="careers-snapshot-container"
              style={{
                background: index === selected ? '#04040C' : 'rgba(3, 3, 12, 0.2)',
                cursor: 'pointer'
              }}
              onClick={() => goTo(index)}
            >
               <div className="careers-snapshot-text"><FormattedMessage id={s.title}/> </div>
            </div>
          )

        })}
      </div>
      <div className="careers-section-four-left-icon-container" onClick={onClickPrev}><img src={leftIcon} className="careers-section-four-icon" /></div>
      <div className="careers-section-four-right-icon-container" onClick={onClickNext}><img src={rightIcon} className="careers-section-four-icon" /></div>
      <Carousel
        ref={carousel}
        dots={false}
        afterChange={() => animationIsOngoing.current = false}
      >
        {cultures.map((c) => {
          return (
            <div key={c.key} className="careers-culture-container">
              <img src={c.img} className="careers-culture" />
              <div className={`${c.position === 'left' ? 'careers-culture-left-corner' : 'careers-culture-right-corner'}`}>
                <div className="carousel-title">
                 <FormattedMessage id={c.title}/> 
                </div>
                <div className="carousel-description">
                  <FormattedMessage id={c.description}/> 
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>
    </div>
  );
}

export default SectionOne;