import React, {useEffect, useRef} from "react"
import { useInView } from 'react-intersection-observer'
import section4litmatch1 from '@/static/img/section4litmatch1.png';
import section4litmatch2 from '@/static/img/section4litmatch2.png';
import textbg1 from '@/static/img/mobilehome/textbg1.png';
import textbg2 from '@/static/img/mobilehome/textbg2.png';
import { classnames } from "@/utils/index";
import "./index.css"
import { FormattedMessage } from 'react-intl';
const SectionFour = () => {

  const { ref: refInView1, inView: inView1} = useInView({
    threshold: 0.3,
  });

  const { ref: refInView2, inView: inView2} = useInView({
    threshold: 0.3,
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
      if(inView1){
        ref1.current.className = classnames(ref1.current.className, 'animation-enter-up')
      }
  }, [inView1])

  useEffect(() => {
    if(inView2){
      ref2.current.className = classnames(ref2.current.className, 'animation-enter-up')
    }
}, [inView2])


  return (
    <div className="mobile-section-four-container">
      <div className="mobile-section-four-row-one" ref={refInView1}>
        <div className="mobile-section-four-row-one-text-container animation-enter-initial-properties" ref={ref1}>
          <img src={textbg1} className="mobile-section-four-row-one-textbg1"/>
          <div className="mobile-section-four-text-fragment-one">
          <FormattedMessage id="deliver_love_trust"/>  
          </div>
          <div className="mobile-section-four-text-fragment-two">
          <FormattedMessage id="through_the_online"/>
          </div>
        </div>
      </div>
      <img src={section4litmatch1} className="mobile-section4litmatch" />


      <div className="mobile-section-four-row-two" ref={refInView2}>
        <div className="mobile-section-four-row-two-text-container animation-enter-initial-properties" ref={ref2}>
        <img src={textbg2} className="mobile-section-four-row-one-textbg2"/>
          <div className="mobile-section-four-text-fragment-three">
          <FormattedMessage id="create_tools_to"/>
            </div>
          <div className="mobile-section-four-text-fragment-four">
          <FormattedMessage id="human_exploration_and"/>
          </div>
        </div>
        <img src={section4litmatch2} className="mobile-section4litmatch" />
      </div>
    </div>
  );
}

export default SectionFour;
