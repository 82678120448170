import React, {useState, useEffect } from 'react'
import MCareers from '../mobile/Careers/index.js';
import PCareers from '../pc/Careers/index.js';
const Careers = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 750)
    useEffect(() => {
        const handleRezize = () => {
            setIsMobile(window.innerWidth <= 750)
        }
        window.addEventListener('resize', handleRezize)
        return () => {
            window.removeEventListener('resize', handleRezize)
        }
    }, [])

    return isMobile ? <MCareers/> : <PCareers/>
}

export default Careers