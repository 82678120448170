import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./index.css"

const footerLinks = [
    {
        key: 'whoWeAre',
        text: 'WHO WE ARE',
        to: '/',
        absolutePath: '/'
    },
    {
        key: 'whatWeBuild',
        text: 'WHAT WE BUILD',
        to: '/what-we-build',
        absolutePath: '/what-we-build'
    },
    {
        key: 'careers',
        text: 'CAREERS',
        to: '/careers',
        absolutePath: '/careers'
    }
]

const Footer = () => {
    const pathname = useLocation().pathname
    return (
        <div className="pcwrapper">
            <div className="footer">
                <div className="constructLabel">@2022 CONSTRUCT</div>
                <nav className="topLinksContainer">
                    {
                        footerLinks.map((link) => {
                            return (
                                <div className="topLink" key={link.key}>
                                        <Link to={link.to} className="link-style" style={{
                                            fontWeight: pathname === link.to ? 600 : 400
                                        }}>
                                            {link.text}
                                            {pathname === link.to && <div className="footer-active-link"/>}
                                        </Link>    
                                </div>
                            )
                        })
                    }
                </nav>
            </div>
        </div>
    )
}

export default Footer;


