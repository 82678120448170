import React, {useEffect, useRef} from "react"
import { useInView } from 'react-intersection-observer'
import "./index.css"
import foundationlogo from '@/static/img/foundationlogo.png';
import litmatchlogo from '@/static/img/litmatchlogo.png';
import UNIlogo from '@/static/img/UNIlogo.png';
import { classnames } from "@/utils/index";
import { FormattedMessage } from 'react-intl';

const historys = [
    {
        img: foundationlogo,
        key: "construct_founded",
        year: 2019,
    },
    // {
    //     img: litmatchlogo,
    //     key: "litmatch_launches",
    //     year: 2020,
    // },
    {
        img: litmatchlogo,
        key: "first_million_users",
        year: 2021,
    },
    {
        img: UNIlogo,
        
        key: "launch_of_creative",
        year: 2022,
    }
]

const SectionFive = () => {

    const { ref, inView} = useInView({
        threshold: 0,
      });
      const ref1 = useRef(null);

      useEffect(() => {
        if(inView){
          ref1.current.className = classnames(ref1.current.className, 'animation-enter-up')
        }
    }, [inView])


    return (
        <div className="mobile-section-five-container" ref={ref}>
            <div className="mobile-section-five-title animation-enter-initial-properties" ref={ref1}>
            <FormattedMessage id="milestones"/> 
            </div>
            <div className="mobile-history-container">
                {
                    historys.map((h) => {
                        return (
                            <div className="mobile-historyItem" key={h.key}>
                                <div className="mobile-historyItemPart1">
                                    <img src={h.img} className="mobile-historylogo" />
                                </div>
                                <div className="mobile-historyItemPart2"><FormattedMessage id={h.key}/></div>
                                <div className="mobile-historyItemPart3">{h.year}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default SectionFive;
