import React from "react"
import "./index.css"
import Router from './router.js'
import 'antd/dist/antd.css';

function App() {
  return (
    <div className="app">
      <Router /> 
    </div>
  );
}

export default App;
