import React, { useEffect, useState, useRef } from "react"
import { useInView } from 'react-intersection-observer'
import "./index.css"
import play from "@/static/img/playwhite.png";
import arrowDown from "@/static/img/arrowDown.png";
import homeVideoStaticPic from "@/static/img/mobilehome/homeVideoStaticPic.png";
// import homeVideo from '@/static/video/home.mp4';
import { openDialog } from '@/components/promisifiedComponent'
import official from '@/static/video/official.mov';
import { FormattedMessage } from 'react-intl';
import '../animation.css';

const SectionOne = ({navigatorVisibility}) => {
  const [officialVideoVisibility, setOfficialVideoVisibility] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const videoRef = useRef(null);
  useEffect(() => {
    // if(!inView){
    //   videoRef.current.pause()
    // } else {
    //   videoRef.current.play()
    // }
  }, [inView])

  const onClickArrow = () => {
    document.getElementById('mobile-home-section-two').scrollIntoView({ behavior: 'smooth'})
  }

  const handleModalVisible = async () => {
    setOfficialVideoVisibility(true)
    await openDialog({
      content: 
      <video  playsInline className="mobile-official" autoPlay loop>
        <source src={official} type="video/mp4" />
      </video>,
      showCancelButton: false,
      showConfirmButton: false
    })
    setOfficialVideoVisibility(false)
  }
  
  return (
    <div className="mobile-section-one-container">
      <div className="mobile-slogan animation-enter-initial-properties animation-enter-up">
        <div className="mobile-slogan-text">
        <FormattedMessage id={"construct_a_virtual"}/>
        </div>
      </div>
      <div className="mobile-play-icon" onClick={() => handleModalVisible()}>
        <img src={play} />
        <span className="mobile-watchText" >Watch</span>
      </div>
      <div className="mobile-main-page-section1-video-container" ref={ref}>
        {/* <video 
        className="mobile-main-page-section1-video" 
        style={{
          visibility: !navigatorVisibility && !officialVideoVisibility ? 'visible' : 'hidden' 
        }}
        playsInline 
        autoPlay 
        muted 
        loop
        controls
        poster={homeVideoStaticPic}
        ref={videoRef}>
          <source src={homeVideo} type="video/mp4" />
        </video> */}
        <img className="mobile-main-page-section1-video" src={homeVideoStaticPic}/>
      </div>
      <div className="mobile-arrow-pointer bounce" onClick={onClickArrow}><img src={arrowDown} className="mobile-arrow-img"/></div>
    </div>

  );
}

export default SectionOne;
