import Mask from '../Mask/mask.js';
import './dialog.css';
import { useEffect } from 'react';

const classNamePrefix = 'litatom-dialog'

const Dialog = (props) => {
    const { header, title, content, showCancelButton = true, showConfirmButton = true } = props;
    const { onCancel, onConfirm } = props;
    const { confirmText, cancelText } = props;
    const { duration = -1 } = props;
    const { rootClassName, headerRootClassName, footerRootClassName, footerConfirmButtonClassName, footerCancelButtonClassName } = props;

    useEffect(() => {
        if (duration > 0) {
            setTimeout(onCancel, duration);
        }
    }, []);

    return (
        <Mask onCancel={onCancel}>
            <div className={`${classNamePrefix}-root ${rootClassName}`}>
                {!!header && <div className={`${classNamePrefix}-header ${headerRootClassName}`}>

                </div>}
                {!!title && <div className={`${classNamePrefix}-title`}>
                    {title}
                </div>}
                {!!content &&
                    <div className={`${classNamePrefix}-content`}>
                        {content}
                    </div>
                }
                <div className={`${classNamePrefix}-footer ${footerRootClassName}`}>
                    {showConfirmButton && <div className={`${classNamePrefix}-confirm-button ${footerConfirmButtonClassName}`} onClick={onConfirm}>
                        {confirmText || "confirm"}
                    </div>}
                    {showCancelButton && <div className={`${classNamePrefix}-cancel-button ${footerCancelButtonClassName}`} onClick={onCancel}>
                        {cancelText || "cancel"}
                    </div>}
                </div>
            </div>
        </Mask>
    )
}

export default Dialog