import React, { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

import "./index.css";
import unilogo from "@/static/img/wwb/wwbunilogo.png";
import layer1 from "@/static/img/wwb/layer1.png";
import UNIintroVideo from "@/static/video/UNIintro.mp4";
import { useInView } from "react-intersection-observer";
import { classnames } from "@/utils/index";

const SectionTwo = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0,
  });

  const imgRef = useRef(null);
  const videoRef = useRef(null);
  useEffect(() => {
    if (!inView) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  }, [inView]);

  useEffect(() => {
    if (inView1) {
      imgRef.current.className = classnames(
        imgRef.current.className,
        "animation-wwe-title-showUp"
      );
    }
  }, [inView1]);

  return (
    <div
      className="what-we-build-section-two-container"
      id="whatwebuild-section-two"
    >
      <div className="what-we-build-section-two-left-part">
        <div
          className="what-we-build-section-two-left-part-container"
          ref={imgRef}
        >
          <img src={unilogo} className="wwb-unilogo" />
          <div className="wwb-universe" ref={ref1}>
            Universe
          </div>
          <div className="wwb-cttf">
            <FormattedMessage id="cttfi" />
          </div>
          <div className="coming-soon">
            <span className="coming-soon-text">Coming soon</span>
          </div>
        </div>
      </div>
      <div className="what-we-build-section-two-right-part">
        <img
          src={layer1}
          className="what-we-build-section-two-right-part-layer"
          ref={ref}
        />
        <video
          playsInline
          className="wwb-page-section2-video"
          muted
          loop
          ref={videoRef}
        >
          <source src={UNIintroVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default SectionTwo;
