import React from "react"
import "./index.css"
import arrowDown from "@/static/img/arrowDown.png";
import { FormattedMessage } from "react-intl";
const SectionOne = () => {
  const onClickArrow = () => {
    document.getElementById('mobile-career-section-two').scrollIntoView({ behavior: 'smooth'})
  }
  
  return (
    <div className="mobile-careers-section-one-container">
      <div className="mobile-arrow-pointer bounce" onClick={onClickArrow}><img src={arrowDown} className="mobile-arrow-img"/></div>
      <div 
        className="mobile-careers-section-one-title animation-enter-initial-properties animation-enter-up">
           <FormattedMessage id="people_make_things"/>  
      </div>
    </div>
  );
}

export default SectionOne;