import React from "react"
import "./index.css"
import { Popup } from "antd-mobile"
import logo from '@/static/img/logo.png'
import navigatorIcon from '@/static/img/mobilehome/navigateIcon.png'
import { Link, useLocation } from 'react-router-dom';

const headerLinks = [
    {
        key: 'whoWeAre',
        text: 'WHO WE ARE',
        to: '/'
    },
    {
        key: 'whatWeBuild',
        text: 'WHAT WE BUILD',
        to: '/what-we-build'
    },
    {
        key: 'careers',
        text: 'CAREERS',
        to: '/careers',
    }
]

const Header = (props) => {
    const { setNavigatorVisibility, navigatorVisibility} = props;
    const openNavigator = () => {
        console.log(setNavigatorVisibility)
        setNavigatorVisibility(true)
    }
    const pathname = useLocation().pathname
    return (
        <>
            <div className="mobile-header">
                <Link to={'/'}><img src={logo} className="mobile-logo" ></img></Link>
                <img src={navigatorIcon} className="mobile-navigator-icon" onClick={() => openNavigator()}/>
            </div>
            <Popup
                visible={navigatorVisibility}
                onMaskClick={() => {
                    setNavigatorVisibility(false)
                }}
                position='top'
                bodyStyle={{ 
                    height: '60vh',
                    background: '#15161C'
                }}
                >
                <div className="mobile-navigator-container">
                    <img src={logo} className="mobile-navigator-logo" ></img>
                    <div  className="mobile-navigator-links-container">
                        {
                            headerLinks.map((link, index) => {
                                return (
                                    <Link to={link.to} className="mobile-navigator-link" key={link.key}>
                                        <div 
                                            className="mobile-navigator-link-text"
                                            style={{
                                                fontWeight: pathname === link.to ? 500 : 400,
                                                opacity: pathname === link.to ? 1 : 0.6,
                                                borderBottom: index === 1 ? '1px solid #4D4D4D' : '',
                                                borderTop:index === 1 ? '1px solid #4D4D4D' : '',
                                            }}
                                            onClick={() => setNavigatorVisibility(false)}
                                            >
                                            {link.text}
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </Popup>
        </>
      
    );
}

export default Header;
