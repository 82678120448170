import React from "react"
import careerbenefit from '@/static/img/careers/careerbenefit.png'
import "./index.css"
import { FormattedMessage } from "react-intl";

const SectionOne = () => {
  return (
    <div className="mobile-careers-section-five-container">
      <img src={careerbenefit} className="mobile-career-benefit" />

      <div className="mobile-careers-section-text-container">
        <div className="mobile-careers-section-five-title">
          Benefits and perks
            </div>
        <div className="mobile-career-section-five-subtitle">
        <FormattedMessage id="the_well_being"/>
      </div>
      </div>
    </div>
  );
}

export default SectionOne;