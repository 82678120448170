import './mask.css';
const classNamePrefix = 'litatom-mask'

const mask = (props) => {
    const { onCancel, children } = props;
    return (
        <div className={`${classNamePrefix}-root`} onClick={onCancel}>
            {children}
        </div>
    )
}

export default mask;