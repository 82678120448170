import React, {useState, useEffect } from 'react'
import MWhatWeBuild from '../mobile/WhatWeBuild/index.js';
import PWhatWeBuild from '../pc/WhatWeBuild/index.js';
const WhatWeBuild = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 750)
    useEffect(() => {
        const handleRezize = () => {
            setIsMobile(window.innerWidth <= 750)
        }
        window.addEventListener('resize', handleRezize)
        return () => {
            window.removeEventListener('resize', handleRezize)
        }
    }, [])
    return isMobile ? <MWhatWeBuild/> : <PWhatWeBuild/>
}

export default WhatWeBuild