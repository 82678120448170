import React, {useEffect, useState, useRef} from "react"
import { useInView } from 'react-intersection-observer'
import layer1 from '@/static/img/mobilehome/layer1.png';
import layer2 from '@/static/img/mobilehome/layer2.png';
import layer3 from '@/static/img/mobilehome/layer3.png';
import { classnames } from "@/utils/index";
import { FormattedMessage } from "react-intl";
import "./index.css"
const SectionThree = () => {

  const { ref: refInView1, inView: inView1} = useInView({
    threshold: 0.4
  });
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
      if(inView1){
        ref1.current.className = classnames(ref1.current.className, 'animation-enter-up')
        ref2.current.className = classnames(ref2.current.className, 'animation-enter-up')
      }
  }, [inView1])

  const { ref:refInView2, inView: inView2} = useInView({
    threshold: 0.3,
  })

  const [circleVisibility, setCircleVisbility] = useState(false);

  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const ref5 = useRef(null)
  const rings = [1,2,3,4,5,6,7,8]

  useEffect(() => {
    if(inView2){
      ref3.current.className = classnames(ref3.current.className, 'animation-fanIn')
      ref4.current.className = classnames(ref4.current.className, 'animation-showup')
      ref5.current.className = classnames(ref5.current.className, 'animation-showup1')
    }
  }, [inView2])



  return (
    <div className="mobile-section-three-container" ref={refInView1}>
      <div className="mobile-section3-title animation-enter-initial-properties" ref={ref1}>
      <FormattedMessage id="multinational_users_across"/>
       </div>
      <div className="mobile-section3-subTitle animation-enter-initial-properties" ref={ref2}>
        <FormattedMessage id="online_users_cover"/>
       </div>
       <div className={`mobile-section-three-layer-container`} ref={refInView2}>
        <div className={`mobile-section-three-layer0`} ref={ref3}/>
        <img src={layer1} className={`mobile-section-three-layer1`} />
        <img src={layer2} className={`mobile-section-three-layer2`} ref={ref4}/>
        <img src={layer3} className={`mobile-section-three-layer3`} ref={ref5} onAnimationEnd={() => setCircleVisbility(true)}/>
        {circleVisibility && rings.map((ring) => {
          return (
            <div className={`ring mobile-position${ring} animation-showup${ring}`} key={ring}>
              <div className="circle" />
              <div className="circle1" />
              <div className="circle2"/>
              <div className="circle3"/>
          </div>
          )
        })}
      </div>
    </div>
  );
}

export default SectionThree;
