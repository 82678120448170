import React, { useEffect, useState } from "react"
import "./index.css"
import Header from '../Header/index'
import Footer from '../Footer/index'
import SectionOne from './sectionOne.js'
import SectionTwo from './sectionTwo.js'
const WhatWeBuild = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 100);
  }, [])

  const [navigatorVisibility, setNavigatorVisibility] = useState(false);

  return (
    <>
      <Header setNavigatorVisibility={setNavigatorVisibility} navigatorVisibility={navigatorVisibility}/>
      <SectionOne />
      <SectionTwo navigatorVisibility={navigatorVisibility}/>
      <Footer />
    </>
  );
}

export default WhatWeBuild;