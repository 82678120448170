import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer";
import layer0 from '@/static/img/home/layer0.png';
import layer1 from '@/static/img/home/layer1.png';
import layer2 from '@/static/img/home/layer2.png';
import { classnames } from "@/utils/index";
import { FormattedMessage } from 'react-intl';
import "./index.css"

const SectionThree = () => {

  const { ref, inView } = useInView({
    threshold: 0.3,
  })

  const [circleVisibility, setCircleVisbility] = useState(false);

  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const rings = [1, 2, 3, 4, 5, 6, 7, 8]

  useEffect(() => {
    if (inView) {
      ref1.current.className = classnames(ref1.current.className, 'animation-fanIn')
      ref2.current.className = classnames(ref2.current.className, 'animation-showup')
      ref3.current.className = classnames(ref3.current.className, 'animation-showup1')
    }
  }, [inView])

  return (
    <div className="section-three-container">
      <div className="section-three-title">
        <FormattedMessage id="multinational_users_across"/>
      </div>
      <div className="section-three-subTitle">
        <FormattedMessage id="online_users_cover"/>
      </div>
    
      <div className={`section-three-layer-container`} ref={ref}>
        <div className={`section-three-layer0`} ref={ref1} />
        <img src={layer0} className={`section-three-layer1`} />
        <img src={layer1} className={`section-three-layer2`} ref={ref2} />
        <img src={layer2} className={`section-three-layer3`} ref={ref3} onAnimationEnd={() => setCircleVisbility(true)} />
        {circleVisibility && rings.map((ring) => {
          return (
              <div key={ring} className={`ring position${ring} animation-showup${ring}`}>
                <div className="circle" />
                <div className="circle1" />
                <div className="circle2" />
                <div className="circle3" />
              </div>
          )
        })}
      </div>
    </div>
  );
}

export default SectionThree;
