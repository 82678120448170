import React, { useEffect, useRef } from "react";
import "./index.css";
import bg from "@/static/img/careers/careersbg2.png";
import { useInView } from "react-intersection-observer";
import { classnames } from "@/utils";
import { FormattedMessage } from "react-intl";

const SectionSix = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  useEffect(() => {
    if (inView) {
      ref1.current.className = classnames(
        ref1.current.className,
        "animation-careers-title"
      );
      ref2.current.className = classnames(
        ref2.current.className,
        "animation-careers-title"
      );
    }
  }, [inView]);

  return (
    <div>
      <div className="section-seven-container" ref={ref}>
        <img src={bg} className={"section-seven-container-bg"} alt="bg" />
        <div className="careers-section-six-title" ref={ref1}>
          <FormattedMessage id="construct_was_founded" />
        </div>
        <div
          className="constructyourfutureLink"
          ref={ref2}
          onClick={() => {
            window.open(
              "https://app.mokahr.com/social-recruitment/litatom/42188?edit=1#/"
            );
          }}
        >
          <div className="constructyourfutureText">
            <FormattedMessage id="construct_your_future" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSix;
