import React from "react"
import "./index.css"
import arrowDown from "@/static/img/arrowDown.png";
import app from '@/static/img/wwb/wwbapp.png';
import litmatchlogo from '@/static/img/wwb/wwblitmatchlogo.png';
import { FormattedMessage } from 'react-intl';


const SectionOne = () => {
  const onClickArrow = () => {
    document.getElementById('whatwebuild-section-two').scrollIntoView({ behavior: 'smooth'})
  }
  return (
    <div className="what-we-build-section-one-container">
      <div className="arrow-pointer bounce" onClick={onClickArrow}><img src={arrowDown} className="arrow-img"/></div>
      <div className="left-app-container animation-left-app">
          <img src={app} className="left-app-img" />
      </div>
      <div className="right-litmatch-container animation-wwe-title-showUp">
        <img src={litmatchlogo} className="wwblitmatchlogo" />
        <div className="wwb-litmatch-title">Litmatch</div>
        <div className="wwb-litmatch-subtitle">
          <FormattedMessage id="meet_people_emotional"/>
        </div>
        <div className="gotoLitmatch" onClick={() => {
          window.open("https://www.litmatchapp.com/");   
        }}>
          <span className="gotoLitmatchText" >Go to Litmatch</span>
        </div>
      </div>
    </div>
  );
}

export default SectionOne;