import React, {useState, useEffect } from 'react'
import MHome from '../mobile/Home/index.js';
import PHome from '../pc/Home/index.js';
const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 750)
    useEffect(() => {
        const handleRezize = () => {
            setIsMobile(window.innerWidth <= 750)
        }
        window.addEventListener('resize', handleRezize)
        return () => {
            window.removeEventListener('resize', handleRezize)
        }
    }, [])

    return isMobile ? <MHome/> : <PHome/>
}

export default Home