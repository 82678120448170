import React, {useEffect, useRef} from "react"
import { useInView } from 'react-intersection-observer'
import "./index.css"
import capa1 from '@/static/img/capa1.png';
import capa2 from '@/static/img/capa2.png';
import capa3 from '@/static/img/capa3.png';
import { classnames } from "@/utils/index";
import { FormattedMessage } from 'react-intl';

const capabilities = [
    {
        img: capa1,
        text: "multimedia_interaction",
        subText: "make_you_feel",
        key: "multimedia_interaction",
    },
    {
        img: capa2,
        text: "algorithm",
        subText: "understand_what_you",
        key: "Algorithm",
    },
    {
        img: capa3,
        text: "security_technology",
        subText: "set_everything_encrypted",
        key: "Security technology",
    },
]

const SectionSix = () => {

    const { ref, inView} = useInView({
        threshold: 0,
      });
    const ref1 = useRef(null);
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const refs = [ref2, ref3, ref4]
    useEffect(() => {
        if(inView){
          ref1.current.className = classnames(ref1.current.className, 'animation-enter-up')
          refs.forEach(ref => {
            ref.current.className = classnames(ref.current.className, 'animation-enter-up-and-rotate')
          })
        }
    }, [inView])

    return (
        <div className="mobile-section-six-container" ref={ref}>
            <div className="mobile-section-six-title animation-enter-initial-properties" ref={ref1}>Capability</div>
            <div className="mobile-capability-container">
                {
                    capabilities.map((h, index) => {
                        return (
                            <div 
                                className="mobile-capabilityItem" 
                                key={h.key}
                                style={{
                                    animationDelay: `${index * 0.1}s`
                                }}
                                ref={refs[index]}
                            >
                                <div className="mobile-capabilityItemPart1"><img src={h.img} className="mobile-capabilitylogo" /></div>
                                <div className="mobile-capabilityItemPart2"> <FormattedMessage id={h.text}/></div>
                                <div className="mobile-capabilityItemPart3"><FormattedMessage id={h.subText}/>  </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default SectionSix;
