import React, { useEffect, useRef } from "react";
import "./index.css";
// import bag from "@/static/img/careers/bag.png";
import particle from "@/static/video/particle.mp4";
import layer1 from "@/static/img/careers/layer1.png";
import { useInView } from "react-intersection-observer";
import { classnames } from "@/utils";
import { FormattedMessage } from "react-intl";

const SectionTwo = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0.4,
  });
  const videoRef = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    if (!inView) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  }, [inView]);

  useEffect(() => {
    if (inView1) {
      ref2.current.className = classnames(
        ref2.current.className,
        "animation-careers-title"
      );
      ref3.current.className = classnames(
        ref3.current.className,
        "animation-careers-title"
      );
    }
  }, [inView1]);

  return (
    <div
      className="careers-section-two-container"
      ref={ref1}
      id="career-section-two"
    >
      <div className="careers-section-two-content-container" ref={ref2}>
        <div className="careers-section-two-title">
          <FormattedMessage id="construct_your_future" />
        </div>
        <div className="careers-section-two-subtitle">
          <FormattedMessage id="construct_tech_is" />
        </div>
      </div>
      <div className="allCareersVideoLayerContainer">
        <div
          className="allCareersLink"
          onClick={() => {
            window.open(
              "https://app.mokahr.com/social-recruitment/litatom/42188?edit=1#/"
            );
          }}
          ref={ref3}
        >
          <div className="bagIcon" />
          <div className="allCareersText">Careers</div>
        </div>
        <img src={layer1} className="allCareersVideoLayer" ref={ref} />
        <video
          playsInline
          className="allCareersVideo"
          muted
          loop
          ref={videoRef}
        >
          <source src={particle} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default SectionTwo;
