import React from "react"
import "./index.css"
import arrowDown from "@/static/img/arrowDown.png";
import { FormattedMessage } from "react-intl";
const SectionOne = () => {
  const onClickArrow = () => {
    document.getElementById('career-section-two').scrollIntoView({ behavior: 'smooth'})
  }
  return (
    <div className="careers-section-one-container">
      <div className="arrow-pointer bounce" onClick={onClickArrow}><img src={arrowDown} className="arrow-img"/></div>
      <div className="careers-section-one-title animation-careers-title">
        <FormattedMessage id="people_make_things"/>  
      </div>
    </div>
  );
}

export default SectionOne;