import React from "react";
import "./index.css";
import bg from "@/static/img/mobilehome/founderbg.png";
import { FormattedMessage } from "react-intl";
const SectionSix = () => {
  return (
    <>
      <div className="mobile-career-section-six-container">
        <img
          src={bg}
          className="mobile-career-section-six-container-bg"
          alt="bg"
        />

        <div className="mobile-section-six-title">
          <FormattedMessage id="construct_was_founded" />
        </div>

        <div
          className="mobile-constructyourfutureLink"
          onClick={() => {
            window.open(
              "https://app.mokahr.com/social-recruitment/litatom/42188?edit=1#/"
            );
          }}
        >
          <div className="mobile-constructyourfutureText">
            <FormattedMessage id="construct_your_future" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionSix;
