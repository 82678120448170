import React from "react"
import { HashRouter, Route} from 'react-router-dom';
import Home from './entrance/home.js';
import WhatWeBuild from './entrance/whatWeBuild.js';
import Careers from './entrance/careers.js';

const Router = () => {
    return (
        <HashRouter>
            <Route path='/' exact component={Home}></Route>
            <Route path='/what-we-build' exact component={WhatWeBuild}></Route>
            <Route path='/careers' exact component={Careers}></Route>
        </HashRouter>
    )
}

export default Router
