import React from "react"
import { Link, useLocation } from 'react-router-dom';
import "./index.css"
import logo from '@/static/img/logo.png'


const headerLinks = [
    {
        key: 'whoWeAre',
        text: 'WHO WE ARE',
        to: '/'
    },
    {
        key: 'whatWeBuild',
        text: 'WHAT WE BUILD',
        to: '/what-we-build'
    },
    {
        key: 'careers',
        text: 'CAREERS',
        to: '/careers',
    }
]

const Header = () => {
    const pathname = useLocation().pathname

    return (
        <div className="header">
            <Link to={'/'}><img src={logo} className="logo"></img></Link> 
            <nav className="topLinksContainer">
                {
                    headerLinks.map((link) => {
                        return (
                            <div className="topLink" key={link.key}>
                                    <Link to={link.to} className="link-style" style={{
                                       fontWeight: pathname === link.to ? 600 : 400
                                    }} >
                                        {link.text}
                                        {pathname === link.to && <div className="active-link"/>}
                                    </Link>    
                            </div>
                        )
                    })
                }
            </nav>
        </div>
    );
}

export default Header;
