import React, { useState, useEffect, useRef } from "react"
import { useInView } from 'react-intersection-observer'
import homeSection2 from '@/static/video/homeSection2.mp4'
import cover from '@/static/img/home/cover.png';
import { FormattedMessage } from 'react-intl';
import "./index.css"

const c = 12;
const r = 560;
const u = 14000000;
const time = 1200;
const interval = 40;
const updateTime = time / interval;
const SectionTwo = () => {

  const [countries, setCountries] = useState(6);
  const cRef = useRef(6);

  const [rate, setRate] = useState(300);
  const cRate = useRef(300);

  const [users, setUsers] = useState(10000000);
  const cUser = useRef(10000000);
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    let timer = null;
    if (inView) {
      timer = setInterval(() => {
        cRef.current = cRef.current + Math.ceil((c - 6) / updateTime);
        if (cRef.current <= c) {
          setCountries(cRef.current)
        }
        if (cRef.current === c) {
          clearInterval(timer)
        }
      }, 100)
    }

    return () => {
      clearInterval(timer)
    }
  }, [inView])

  useEffect(() => {
    let timer = null;
    if (inView) {
      timer = setInterval(() => {
        cRate.current = cRate.current + Math.floor((r - 300) / updateTime);
        if (cRate.current <= r) {
          setRate(cRate.current)
        } else {
          setRate(r)
          clearInterval(timer)
        }
      }, interval)
    }
    return () => {
      clearInterval(timer)
    }
  }, [inView])

  useEffect(() => {
    let timer = null;
    if (inView) {
      timer = setInterval(() => {
        cUser.current = cUser.current + Math.floor((u - 10000000) / updateTime);
        if (cUser.current <= u) {
          setUsers(cUser.current)
        } else {
          setUsers(u)
          clearInterval(timer)
        }
      }, interval)
    }
    return () => {
      clearInterval(timer)
    }
  }, [inView])


  const { ref: videoInViewRef, inView: videoInView } = useInView()

  useEffect(() => {
    if(!videoInView){
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }, [videoInView])

  const videoRef = useRef(null)

  return (
    <div className="section-two-container" id="home-section-two">
      <div className="section-two-text-container">
        <div className="annual-growth-rate" >
          <div className="fontSize72" ref={ref}>{`${rate}%`}</div>
          <div className="fontSize26"><FormattedMessage id={"annual_user"}/></div>
        </div>
        <div className="user">
          <div className="fontSize72">{users.toLocaleString() + '+'}</div>
          <div className="fontSize26"><FormattedMessage id={"users"}/></div>
        </div>
        <div className="countries">
          <div className="fontSize72">{countries}</div>
          <div className="fontSize26"><FormattedMessage id={"countries"}/></div>
        </div>
      </div>
      <img src={cover} className="home-section2-video-cover" />
      <div className="home-section2-video-container" ref={videoInViewRef}>
        <video  playsInline className="home-section2-video" muted loop ref={videoRef}>
          <source src={homeSection2} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default SectionTwo;
