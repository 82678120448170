import React from "react"
import "./index.css"
// import impact from '@/static/video/impact.mp4'
import impactGif from '@/static/gif/impact.gif'
import { FormattedMessage } from "react-intl"

const SectionThree = () => {
  return (
    <div className="mobile-careers-section-three-container">
      {/* <video  playsInline className="mobile-careers-section-three-content-video" autoPlay muted loop>
        <source src={impact} type="video/mp4" />
      </video> */}
      <img src={impactGif} className="mobile-careers-section-three-content-gif"/>
      <div className="mobile-careers-section-text">
        <div className="mobile-careers-section-three-title">Make an impact</div>
        <div className="mobile-careers-section-three-subtitle">
        <FormattedMessage id="construct_tech_employees"/>  
        </div>
      </div>
      <div className="mobile-section-three-footer"></div>
    </div>
  );
}

export default SectionThree;