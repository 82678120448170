import ReactDOM from 'react-dom';
import Dialog from '../Dialog';
const openDialog = (props) => {
  const {
    title,
    content,
    confirmText,
    cancelText,
    duration = -1,
    rootClassName,
    headerRootClassName,
    footerRootClassName,
    footerConfirmButtonClassName,
    footerCancelButtonClassName,
    showCancelButton = true,
    showConfirmButton = true,
  } = props
  return new Promise((resolve) => {
    const node = document.createElement('div');
    document.body.appendChild(node);
    const removeNode = () => {
      ReactDOM.unmountComponentAtNode(node);
      node.remove();
    };

    const onConfirm = () => {
      removeNode();
      props?.onConfirm?.()
      resolve(true);
    };
    const onCancel = () => {
      removeNode();
      props?.onCancel?.()
      resolve(false);
    };

    const modal =
      <Dialog
        onConfirm={onConfirm}
        onCancel={onCancel}
        showCancelButton={showCancelButton}
        showConfirmButton={showConfirmButton}
        title={title}
        content={content}
        confirmText={confirmText}
        cancelText={cancelText}
        duration={duration}
        rootClassName={rootClassName}
        headerRootClassName={headerRootClassName}
        footerRootClassName={footerRootClassName}
        footerConfirmButtonClassName={footerConfirmButtonClassName}
        footerCancelButtonClassName={footerCancelButtonClassName}
      />
    ReactDOM.render(modal, node);
  });
}

export {
  openDialog,
}

