import React, { useEffect } from "react"
import "./index.css"
import Header from '../Header/index'
import Footer from '../Footer/index'
import SectionOne from './sectionOne.js'
import SectionTwo from './sectionTwo.js'
const WhatWeBuild = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 100);
  }, [])
  return (
    <div className="pcwrapper">
      <Header />
      <SectionOne />
      <SectionTwo />
      <Footer />
    </div>
  );
}

export default WhatWeBuild;