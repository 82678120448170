import en_US from "./en_US.json";
import zh_TW from "./zh_TW.json";
import zh_CN from "./zh_CN.json";

const locales = {
  en: en_US,
  "zh-TW": zh_TW,
  "zh-CN": zh_CN,
};

export { locales };
