import React, { useEffect, useState } from "react"
import "./index.css"
import Header from "../Header/index"
import Footer from "../Footer/index"
import SectionOne from "./sectionOne";
import SectionTwo from "./sectionTwo";
import SectionThree from "./sectionThree";
import SectionFour from "./sectionFour";
import SectionFive from "./sectionFive";
import SectionSix from "./sectionSix";
import SectionSeven from "./sectionSeven";

const Home = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 100);
  }, [])

  const [navigatorVisibility, setNavigatorVisibility] = useState(false);
  return (
    <>
      <Header setNavigatorVisibility={setNavigatorVisibility} navigatorVisibility={navigatorVisibility}/>
      <div className="mobile-home-page-container">
        <SectionOne navigatorVisibility={navigatorVisibility}/>
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <SectionSix />
        <SectionSeven />
      </div>
      <Footer />
    </>
  );
}

export default Home;
