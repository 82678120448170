import React, { useEffect, useRef } from "react"
import { useInView } from "react-intersection-observer"
import { classnames } from '@/utils';
import "./index.css"
import careerbenefit from '@/static/img/careers/careerbenefit.png'
import { FormattedMessage } from "react-intl";



const SectionOne = () => {
  const { ref, inView} = useInView({
    threshold: 0.3,
  })
  
  const ref1 = useRef(null)
  const ref2 = useRef(null)

  useEffect(() => {
    if(inView){
      ref1.current.className = classnames(ref1.current.className, 'animation-careers-title')
      ref2.current.className = classnames(ref2.current.className, 'animation-left-fanIn')
    }
  }, [inView])

  return (
    <div className="careers-section-five-container" ref={ref}>
        <div className={"careers-section-text-container"} ref={ref1}>
            <div className="careers-section-five-title">
                Benefits and perks
            </div>
            <div className="career-section-five-subtitle">
                <FormattedMessage id="the_well_being"/>
            </div>
        </div>
        <div className="career-benefit">
          <img src={careerbenefit} className="career-benefit"/>
          <div className={`careers-layer0`} ref={ref2}/>
        </div>
       
    </div>
  );
}

export default SectionOne;