import { locales } from "@/locales/index";

export const isMobile = (() => {
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    navigator.userAgent
  );
})();

export const classnames = (preClassnames, classname) => {
  let cn = classname.trim();
  const tokens = preClassnames.split(" ");
  const result = [];
  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i] && tokens[i] !== cn) {
      result.push(tokens[i]);
    }
  }
  result.push(cn);
  return result.join(" ");
};

export const getLocales = () => {
  // const language = navigator.language;

  // let locale = language in locales ? language : "en";
  const locale = "en";

  return {
    locale,
    locales: locales[locale],
  };
};
