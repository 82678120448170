import "./index.css"

const Footer = () => {
    return (
        <div className="mobile-careers-section-six-footer">
            <div className="mobile-constructLabel">@2022 CONSTRUCT</div>
        </div>
    )
}

export default Footer;


