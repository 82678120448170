import React from "react"
import "./index.css"
import arrowDown from "@/static/img/arrowDown.png";
import app1 from '@/static/img/mobilewwb/app1.png';
import app2 from '@/static/img/mobilewwb/app2.png';
import litmatchlogo from '@/static/img/wwb/wwblitmatchlogo.png';
import { FormattedMessage } from 'react-intl';

const SectionOne = () => {

  const onClickArrow = () => {
    document.getElementById('mobile-what-we-build-section-two-container').scrollIntoView({ behavior: 'smooth'})
  }
  return (
    <div className="mobile-what-we-build-section-one-container">
      <div className="mobile-wwb-arrow-pointer bounce" onClick={onClickArrow}><img src={arrowDown} className="mobile-arrow-img"/></div>
      <div className="mobile-litmatch-container animation-enter-initial-properties animation-enter-up">
        <img src={litmatchlogo} className="mobile-wwblitmatchlogo" />
        <div className="mobile-wwb-litmatch-title ">Litmatch</div>
        <div className="mobile-wwb-litmatch-subtitle">
        <FormattedMessage id="meet_people_emotional"/>  
        </div>
      </div>
      <div className="mobile-play-icon animation-enter-initial-properties animation-enter-up" onClick={() => {
          window.open("https://www.litmatchapp.com/");   
        }}>
        <span className="mobile-gotoLitmatchText" >Go to Litmatch</span>
      </div>
      <div className="mobile-app-container">
        <div className="mobile-left-app"><img src={app2} className="mobile-left-app-img" /></div>
        <div className="mobile-right-app"><img src={app1} className="mobile-right-app-img" /></div>
      </div>
    </div>
  );
}

export default SectionOne;