import React, { useEffect, useState } from "react"
import "./index.css"
import Header from "../Header/index"
import Footer from "../Footer/index"
import SectionOne from "./sectionOne";
import SectionTwo from "./sectionTwo";
import SectionThree from './sectionThree';
import SectionFour from "./sectionFour";
import SectionFive from "./sectionFive";
import SectionSix from "./sectionSix";
const Careers = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 100);
  }, [])
  const [navigatorVisibility, setNavigatorVisibility] = useState(false);
  return (
    <>
      <Header setNavigatorVisibility={setNavigatorVisibility} navigatorVisibility={navigatorVisibility}/>
      <SectionOne />
      <SectionTwo  navigatorVisibility={navigatorVisibility}/>
      <SectionThree  navigatorVisibility={navigatorVisibility}/>
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <Footer />
    </>
  );
}

export default Careers;
