import React from "react";
import "./index.css";
import bag from "@/static/img/mobilecareers/bag.png";
import particle from "@/static/video/particle.mp4";
import layer1 from "@/static/img/mobilecareers/layer1.png";
import { FormattedMessage } from "react-intl";

const SectionTwo = ({ navigatorVisibility }) => {
  return (
    <div
      className="mobile-careers-section-two-container"
      id="mobile-career-section-two"
    >
      <div className="mobile-careers-section-two-title-container animation-enter-initial-properties animation-enter-up">
        <div className="mobile-careers-section-two-title">
          <FormattedMessage id="construct_your_future" />
        </div>

        <div className="mobile-careers-section-two-subtitle">
          <FormattedMessage id="construct_tech_is" />
        </div>

        <div
          className="mobile-allCareersLink-container"
          onClick={() => {
            window.open(
              "https://app.mokahr.com/social-recruitment/litatom/42188?edit=1#/"
            );
          }}
        >
          <div className="mobile-allCareersLink">
            <img src={bag} className="mobile-bagIcon" alt="bag" />

            <div className="mobile-allCareersText">
              <FormattedMessage id="careers" />
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-career-video-container">
        <img src={layer1} className="mobile-career-video-layer" alt="layer1" />

        <video
          style={{
            visibility: navigatorVisibility ? "hidden" : "visible",
          }}
          className="mobile-career-video"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={particle} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default SectionTwo;
