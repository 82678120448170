import React, { useState, useEffect, useRef } from "react"
import { useInView } from 'react-intersection-observer'
import homeSection2Gif from '@/static/gif/homeSection2.gif';
import "./index.css"

const c = 12;
const r = 560;
const u = 14000000;
const time = 1200;
const interval = 40;
const updateTime = time / interval;

const SectionTwo = () => {
  const [countries, setCountries] = useState(6);
  const cRef = useRef(6);

  const [rate, setRate] = useState(300);
  const cRate = useRef(300);

  const [users, setUsers] = useState(10000000);
  const cUser = useRef(10000000);
  const { ref, inView} = useInView({
    threshold: 0,
  })

  useEffect(() => {
    let timer = null;
    if(inView){
     timer = setInterval(() => {
        cRef.current = cRef.current + Math.ceil((c - 6) / updateTime);
        if (cRef.current <= c) {
          setCountries(cRef.current)
        }
        if (cRef.current === c) {
          clearInterval(timer)
        }
      }, 100)
    }
    
    return () => {
      clearInterval(timer)
    }
  }, [inView])

  useEffect(() => {
    let timer = null;
    if(inView){
      timer = setInterval(() => {
        cRate.current = cRate.current + Math.floor((r - 300) / updateTime);
        if (cRate.current <= r) {
          setRate(cRate.current)
        } else {
          setRate(r)
          clearInterval(timer)
        }
      }, interval)
    }
    return () => {
      clearInterval(timer)
    }
  }, [inView])

  useEffect(() => {
    let timer = null;
    if(inView){
      timer = setInterval(() => {
        cUser.current = cUser.current + Math.floor((u - 10000000) / updateTime);
        if (cUser.current <= u) {
          setUsers(cUser.current)
        } else {
          setUsers(u)
          clearInterval(timer)
        }
      }, interval)
    }
    return () => {
      clearInterval(timer)
    }
  }, [inView])

  return (
    <div className="mobile-section-two-container" id="mobile-home-section-two">
      <div className="mobile-annual-growth-rate">
        <div className="mobile-fontSize72" ref={ref}>{`${rate}%`}</div>
        <div className="mobile-fontSize26">growth</div>
      </div>
      <div className="mobile-user">
        <div className="mobile-fontSize72">{users.toLocaleString() + '+'}</div>
        <div className="mobile-fontSize26">users</div>
      </div>
      <div className="mobile-countries">
        <div className="mobile-fontSize72">{countries}</div>
        <div className="mobile-fontSize26">countries</div>
      </div>
      <div className="mobile-home-section2-video-container">
        <img src={homeSection2Gif} className="mobile-home-section2-gif"/>
      </div>
    </div>
  );
}

export default SectionTwo;
