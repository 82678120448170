import React, {useEffect, useRef} from "react"
import "./index.css"
import impact from '@/static/video/impact.mp4'
import { useInView } from "react-intersection-observer"
import { FormattedMessage } from "react-intl"

const SectionThree = () => {

  const { ref, inView } = useInView({
    threshold: 0,
  })
  const videoRef = useRef(null);
  useEffect(() => {
    if(!inView){
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }, [inView])

  return (
    <div className="careers-section-three-container">
      <div className="careers-section-three-content">
          <div className="careers-section-text">
            <div className="careers-section-three-title">Make an impact</div>
            <div className="careers-section-three-subtitle">
              <FormattedMessage id="construct_tech_employees"/>
            </div>
          </div>
        <div ref={ref}></div>
        <video  playsInline className="careers-section-three-content-video"  muted loop ref={videoRef}>
            <source src={impact} type="video/mp4"/>
        </video>
      </div>
      <div className="section-three-footer"></div>
    </div>
  );
}

export default SectionThree;