import React from "react";
import "./index.css";
import bg from "@/static/img/mobilehome/founderbg.png";
import { FormattedMessage } from "react-intl";
const SectionSeven = () => {
  return (
    <>
      <div className="mobile-section-seven-container">
        <img src={bg} className="mobile-section-seven-container-bg" />
        <div className="mobile-section-seven-title">
          <FormattedMessage id="every_morning_you" />
        </div>
        <div className="mobile-signature">
          - Vincent, <FormattedMessage id="founder_of_construct_tech" />
        </div>
      </div>
    </>
  );
};

export default SectionSeven;
