import React from "react";
import { IntlProvider } from "react-intl";
import App from "./App";
import { getLocales } from "./utils/index";

function IntlApp() {
  const { locale, locales } = getLocales();

  return (
    <IntlProvider locale={locale} messages={locales}>
      <App />
    </IntlProvider>
  );
}
export default IntlApp;
